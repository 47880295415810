import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    tel: "",
    email: "",
    message: "",
    privacyPolicyAgreed: false,
    marketingAgreed: false,
  })
  const [formValidation, setFormValidation] = useState({
    show: false,
    recaptchaError: false,
  })
  const recaptchaRef = React.createRef()

  const isFormValid = () => {
    return formData.name
      && formData.tel
      && formData.email
      && formData.message
      && formData.privacyPolicyAgreed === true;
  }

  const handleChange = e => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    // validate the form
    if (!isFormValid()) {
      setFormValidation({
        ...formValidation,
        show: true,
      })
      return
    }

    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...formData,
      }),
    })
      .then(response => {
        // netlify does a 303 redirect if recaptcha isn't supplied
        if (response.status === 200 && !response.redirected) {
          navigate(form.getAttribute("action"))
        } else {
          setFormValidation({
            ...formValidation,
            recaptchaError: true,
          })
        }
      })
      .catch(error => alert(error))
  }

  const validInputClassName = additionalClasses => {
    return `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary ${additionalClasses}`
  }

  const invalidInputClassName = additionalClasses => {
    return `shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary ${additionalClasses}`
  }

  const getInputClassName = (isValid, additionalClasses) => {
    return formValidation.show && !isValid
      ? invalidInputClassName(additionalClasses)
      : validInputClassName(additionalClasses)
  }

  const checkboxStyle = {
    transform: "scale(1.4)",
    "-moz-transform": "scale(1.4)",
    marginTop: "0.6rem",
    marginRight: "0.6rem",
  };

  return (
    <Layout location="contact">
      <SEO title="Contact Me" />
      <div className="mx-auto prose lg:prose-lg">
        <h1>Contact me</h1>
        <form
          className="bg-white border-2 border-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          name="contact-form"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 req"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className={getInputClassName(formData.name)}
              id="name"
              name="name"
              type="text"
              placeholder="Your name"
              onChange={handleChange}
              required
            />
            {formValidation.show && !formData.name ? (
              <div className="mt-1 mb-6">
                <div className="text-red-500 text-xs italic m-0">
                  Please provide your name
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 req"
              htmlFor="tel"
            >
              Phone
            </label>
            <input
              className={getInputClassName(formData.tel)}
              id="tel"
              name="tel"
              type="text"
              placeholder="Your phone number"
              onChange={handleChange}
              required
            />
            {formValidation.show && !formData.tel ? (
              <div className="mt-1 mb-6">
                <div className="text-red-500 text-xs italic m-0">
                  Please provide your phone number
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 req"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={getInputClassName(formData.email)}
              id="email"
              name="email"
              type="email"
              placeholder="Your email address"
              onChange={handleChange}
              required
            />
            {formValidation.show && !formData.email ? (
              <div className="mt-1 mb-6">
                <div className="text-red-500 text-xs italic m-0">
                  Please provide your email address
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 req"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className={getInputClassName(formData.message, "resize-y")}
              id="message"
              name="message"
              type="text"
              rows="10"
              placeholder="Details of your enquiry"
              onChange={handleChange}
              required
            />
            {formValidation.show && !formData.message ? (
              <div className="mt-1 mb-6">
                <div className="text-red-500 text-xs italic m-0">
                  Please provide a message describing your query
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <div class="flex items-start">
              <input
                id="privacyPolicyAgreed"
                className="cursor-pointer"
                style={checkboxStyle}
                name="privacyPolicyAgreed"
                type="checkbox"
                onChange={handleChange}
                required
              />
              <label
                className="inline-block text-gray-800 req"
                htmlFor="privacyPolicyAgreed"
              >I consent to Greenhill Psychology processing the information that I have supplied above, in order to respond to my enquiry. This is as per the <a href="/privacy/" target="_blank">privacy policy</a>.</label>
            </div>
            {formValidation.show && !formData.privacyPolicyAgreed ? (
              <div className="mt-1 mb-6">
                <div className="text-red-500 text-xs italic m-0">
                  Please confirm your agreement with our privacy policy
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <div class="flex items-start">
              <input
                id="marketingAgreed"
                className="cursor-pointer"
                style={checkboxStyle}
                name="marketingAgreed"
                type="checkbox"
                onChange={handleChange}
              />
              <label
                className="inline-block text-gray-800"
                htmlFor="marketingAgreed"
              >We may from time to time send you details of our services or products that we feel may interest you, including promotional offers (such as money off vouchers or discounted sessions) by email or post. You may opt out of receiving such communications at any time by contacting us. If you would like to receive such offers, please tick this box.</label>
            </div>
          </div>
          <div className="mb-6">
            <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
          </div>
          {formValidation.recaptchaError ? (
            <div className="mt-1 mb-6">
              <div className="text-red-500 text-xs italic m-0">
                Please complete the Recaptcha.
              </div>
            </div>
          ) : null}
          <div className="flex items-center justify-start">
            <button
              className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:border-primary"
              type="submit"
            >
              Submit
            </button>
            <div className="pl-4">
              <Link to="/">Cancel</Link>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ContactPage
